function Footer() {
  return (
    <div className="bg-dark text-white p-5">
      <h4 className="text-center">COSMOS GALLERY</h4>
      <h6 className="text-center">
        Location: 547 W 27th st #528, New York, NY 10001
      </h6>
      <h6 className="text-center">Email: nycosmosgallery@gmail.com</h6>
      <h6 className="text-center">
        Tuesday–Saturday, 10am–6pm. Closed Sunday through Monday.
      </h6>
    </div>
  );
}

export default Footer;
