import history from '../../image/website/history.jpg';

function History(){
  return(
    <div className='p-5'>
      <h4 className='text-center'>History from K&P Gallery to Cosmos Gallery.</h4>
      <img className='w-100 p-5'src={history} alt='history'/>
    </div>
  )
}

export default History;