import artists from "../../data/artists";

function Content(props) {
  return (
    <div className="d-flex col-12 p-4 flex-wrap">
      <div className="col-3 mobile-width">
        <img className="img-1-1" src={artists[props.id].image} alt="artist" />
      </div>
      <div className="col-9 px-5 mobile-padding mobile-width">
        <h2>{artists[props.id].artist}</h2>
        <p>{artists[props.id].body}</p>
      </div>
    </div>
  );
}

function Artists() {
  return (
    <div className="px-5 mobile-padding">
      <h4 className="text-center p-5">Explore Cosmos Artists.</h4>
      <div className="d-flex flex-wrap">
        <Content id={0} />
        <Content id={1} />
        <Content id={2} />
      </div>
    </div>
  );
}

export default Artists;
