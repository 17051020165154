import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/pages/Main";
import Exhibitions from "./components/pages/Exhibitions";
import ExhibitionPost from "./components/pages/ExhibitionPost";
import Artists from "./components/pages/Artists";
import History from "./components/pages/History";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/*" element={<Navigate to="/"></Navigate>}></Route>
        <Route path="/" element={<Main />}></Route>
        <Route path="/exhibitions/:id" element={<ExhibitionPost />}></Route>
        <Route path="/exhibitions" element={<Exhibitions />}></Route>
        <Route path="/artists" element={<Artists />}></Route>
        <Route path="/history" element={<History />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
