import { Link } from "react-router-dom";
import exhibitions from "../../data/exhibitions";

function Content(props) {
  return (
    <div className="col-4 p-4 mobile-padding mobile-width">
      <Link className="text-dark" to={`/exhibitions/${props.id}`}>
        <img
          className="img-16-9"
          src={exhibitions[props.id].images[0]}
          alt="exhibition"
        />
        <h6>
          <br />
          EXHIBITION
        </h6>
        <h4>{exhibitions[props.id].artist}</h4>
        <h4 className="fst-italic">{exhibitions[props.id].title}</h4>
        <h6>{exhibitions[props.id].date}</h6>
      </Link>
    </div>
  );
}

function Exhibitions() {
  return (
    <div className="px-5 mobile-padding">
      <h4 className="text-center p-5">Explore Our Historical Exhibitions!!!</h4>
      <div className="d-flex flex-wrap">
        {exhibitions.map((_, idx) => (
          <Content key={idx} id={idx} />
        ))}
      </div>
    </div>
  );
}

export default Exhibitions;
