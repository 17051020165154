import exhibitions from "../../data/exhibitions";
import { useParams } from "react-router-dom";

function ExhibitionPost(props) {
  window.scrollTo(0, 0);
  let { id } = useParams();
  return (
    <div className="d-flex flex-wrap p-5">
      <h2 className="w-100 text-center">{exhibitions[id].artist}</h2>
      <h2 className="w-100 text-center">{exhibitions[id].title}</h2>
      <h4 className="w-100 text-center pb-5">{exhibitions[id].date}</h4>
      <div style={{width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}><img className="jaewonpost" src={require('../../image/exhibitions/thewaythingsgo/9.jpg')}/></div>
      <div style={{padding: '5vw'}}>
      JAE WON JUNG SOLO EXHIBITION <br/><br/>
      THE WAY THINGS GO <br/><br/>
      SEPTEMBER 22- SEPTEMBER 24, 2024 <br/><br/>
      547 W 27th st #528, New York, NY. 10001, NY COSMOS GALLERY<br/><br/>
      For artist Jae Won Jung, lines are the artist’s most basic gestural indications. Jung considers lines as the starting point of the extension of brushstrokes connected to the canvas, and considers them the basic principle of all compositions that lay the foundation of a work regardless of genre or era. Abstract works expressed in lines can ultimately be seen as visual expressions of appreciation itself rather than creating a certain shape. Therefore, Jung thinks that the process of drawing lines is the core of what she wants to reflect and to give the simplicity and joy that comes from the vivid texture itself.<br/><br/>
      Through this exhibition, Jae Won Jung wanted to create an opportunity to see the boundary between lines and the human body in a different way through the artist’s unique method of interpreting the transformation of the human body as an object. Her interpretation of the human body is a simple visual selection and acts as an object that inspires the varied expression of lines to begin more freely. Jung interpreted each work with inspiration from the form of the human body, which is made up of lines more beautiful than any other line. Through the lines of the human body, the artist began to unconsciously move away from the natural aspects of reality. To present such a pleasure moment, the artist limited the pictorial language to absolutely pure basic color planes and black lines. <br/><br/>
      Each work is the embodiment of the artist's unique attempts to express principles in irregularities derived from simple elements. Through the intersection of black lines and verticals in the work, the artist created the distortion and play of lines originating from the human body as the artist's ultimate vision. Jung further explored the powerful effect of straight lines and the interrelationship between unexpected and unchanging elements. The artist completed the series through various theories and experiences on the infinite possibilities of effectively using lines in her work, and hinted at the artist's perspective on where and how to place lines in each composition and the infinite transformation of lines in the work. <br/><br/>
      Each artistic practice features the thickness of the basic color's restrained black lines, the size of the crossed lines depicting geometric compositions, and the transformation of irregular shapes, refining the artist's unique vocabulary in an unstable balance and presenting it as a work. Through this exhibition, the artist attempted to convey a tremendous amount of visual information dealing with the implied motif of the human body through the artist's distinct expression of lines. In addition, the artist's simple gestural perspective, which is reminiscent of strokes like oriental mark making, shows the spontaneity of consciousness as well as playful and unconscious aspects. In other words, Jung sought to compress the essence of various materials through the artist's spontaneously expressed, lively line gestures, and also record images with dynamic forms and moving lines. <br/><br/>
      _______________________________________________________________________________________________<br/><br/>
      Jae Won Jung is a contemporary artist from South Korea. As a painter, installation artist, photographer, and film director, the artist explores the aesthetic possibilities of painting, photography, and film. Jung works to express her own thoughts and emotions in a unique way using various media such as painting, installation art, photography, and film, combining poetic images with philosophical and reflective storytelling.
      <br/><br/><br/></div>
      {exhibitions[id].images.map((image, index) => (
        <a className="exhibition__image" href={image}>
          <img key={index} className="p-2 img-1-1" src={image} alt="art work" />
        </a>
      ))}
    </div>
  );
}

export default ExhibitionPost;
