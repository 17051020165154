import { Link } from "react-router-dom";
import logo from "../image/website/logo.png";

function Header() {
  let nav = "p-4 text-dark fs-3 fw-bold fw-600 mobile-h mobile-h-p";

  return (
    <div className="row align-items-center m-0" style={{paddingTop: "30px", paddingLeft: "10px", paddingRight: "10px"}}>
      <h1 className="col-4 fs-1 p-1 fw-600 mobile-h1">
        <Link className="text-decoration-none" to="/">
          <img
            src={logo}
            alt="COSMOS"
            style={{ width: "100px", height: "auto" }}
          />
        </Link>
      </h1>
      <div className="d-flex col-8 justify-content-end">
        <Link to="/artists">
          <h5 className={nav}>ARTISTS</h5>
        </Link>
        <Link to="/exhibitions">
          <h5 className={nav}>EXHIBITIONS</h5>
        </Link>
        <Link to="/history">
          <h5 className={nav}>HITORY</h5>
        </Link>
      </div>
    </div>
  );
}

export default Header;
