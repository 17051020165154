import { Link } from "react-router-dom";
import exhibitions from "../../data/exhibitions";
import gallery from "../../image/website/gallery.jpg";

function Content(props) {
  return (
    <div className="col-4 p-4 mobile-width">
      <Link className="text-dark" to={`/exhibitions/${props.id}`}>
        <img
          className="img-16-9"
          src={exhibitions[props.id].images[0]}
          alt="exhibition"
        />
        <h6>
          <br />
          EXHIBITION
        </h6>
        <h4>{exhibitions[props.id].artist}</h4>
        <h4 className="fst-italic">{exhibitions[props.id].title}</h4>
        <h6>{exhibitions[props.id].date}</h6>
      </Link>
    </div>
  );
}

function Main() {
  let style = {
    backgroundImage: "url(" + exhibitions[0].images[0] + ")",
    aspectRatio: "16/9",
    objectFit: "cover",
    backgroundSize: "cover",
  };

  return (
    <div className="row px-6 m-0 mobile-padding">
      <Link
        to="/exhibitions/0"
        style={{ width: "100%", textDecoration: "none" }}
      >
        <div
          className="d-flex col-12 justify-content-center align-items-center"
          style={style}
        >
          <div className="d-flex w-25 img-16-9 bg-light text-dark justify-content-center align-items-center flex-wrap text-center py-5 mobile-width-75">
            <h4 className="w-100">{exhibitions[0].artist}</h4>
            <h4 className="w-100">{exhibitions[0].title}</h4>
            <h6 className="w-100">{exhibitions[0].date}</h6>
          </div>
        </div>
      </Link>
      <div className="col-6 p-5 mobile-width">
        <h4>WELCOMES</h4>
        <h2>Visiting Cosmos</h2>
        <br />
        <p>
          COSMOS Gallery is located in the heart of Chelsea, New York, providing
          excellent collections of contemporary art. Founded in 2010, South
          Korea, COSMOS gallery is internationally renowned for selling art. At
          Hong Kong Art Fair in 2013 and 2014, the gallery sold out its
          collection of artists Kiesoo Park and InJoo Kang.
          <br />
          <br />
          Since having moved to Chelsea in 2015, COSMOS Gallery has represented
          over 300 artists including senior and young artists worldwide. It has
          also provided creative and challenging platform for emerging artists.
          Now, the gallery has a wide range of audience and collectors to
          appreciate our collection. Also, COSMOS gallery promotes various
          commissions and collaboration between artists, clients and collectors.
          <br />
          <br />
          Suji Kim
          <br />
          Director of COSMOS Gallery
        </p>
      </div>
      <div className="d-flex col-6 p-5 mobile-width moblie-padding">
        <img className="img-16-9 w-100" src={gallery} alt="gallery" />
      </div>
      <div className="d-flex col-12 flex-wrap">
        {exhibitions.map((_, idx) => {
          return <Content key={idx} id={idx} />;
        })}
      </div>
    </div>
  );
}

export default Main;
