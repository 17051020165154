function Add(_title, _artist, _date, imageCount) {
  let object = {
    title: _title,
    artist: _artist,
    date: _date,
    images: [],
  };
  for (let i = 1; i < imageCount + 1; i++) {
    object.images.push(
      require(`../image/exhibitions/${_title
        .toLowerCase()
        .replaceAll(" ", "")}/${i}.jpg`)
    );
  }
  exhibitions.unshift(object);
}

let exhibitions = [
  {
    title: "From the light",
    artist: "Sangmin Yoon",
    date: "Nov 1st, 2022 - Nov 9th, 2022",
    images: [
      require("../image/exhibitions/fromthelight/1.jpg"),
      require("../image/exhibitions/fromthelight/2.jpg"),
      require("../image/exhibitions/fromthelight/3.jpg"),
      require("../image/exhibitions/fromthelight/4.jpg"),
      require("../image/exhibitions/fromthelight/5.jpg"),
      require("../image/exhibitions/fromthelight/6.jpg"),
      require("../image/exhibitions/fromthelight/7.jpg"),
      require("../image/exhibitions/fromthelight/8.jpg"),
      require("../image/exhibitions/fromthelight/9.jpg"),
      require("../image/exhibitions/fromthelight/10.jpg"),
      require("../image/exhibitions/fromthelight/11.jpg"),
      require("../image/exhibitions/fromthelight/12.jpg"),
      require("../image/exhibitions/fromthelight/13.jpg"),
      require("../image/exhibitions/fromthelight/14.jpg"),
      require("../image/exhibitions/fromthelight/15.jpg"),
      require("../image/exhibitions/fromthelight/16.jpg"),
      require("../image/exhibitions/fromthelight/17.jpg"),
      require("../image/exhibitions/fromthelight/18.jpg"),
      require("../image/exhibitions/fromthelight/19.jpg"),
      require("../image/exhibitions/fromthelight/20.jpg"),
      require("../image/exhibitions/fromthelight/21.jpg"),
      require("../image/exhibitions/fromthelight/22.jpg"),
      require("../image/exhibitions/fromthelight/23.jpg"),
      require("../image/exhibitions/fromthelight/24.jpg"),
    ],
  },
];
Add("Four season", "Byeon Ok Ran", "Dec 21st, 2021 - Dec 24th, 2021", 15);

Add(
  "Spring Collection",
  "Group Exhibition",
  "Feb 28th, 2023 - Mar, 6th, 2023",
  15
);

Add("Mindscape", "Willy Kim", "May 10th, 2023 - May, 21st, 2023", 22);

Add("Friends", "Group Exhibition", "May 25th, 2023 - May 31st, 2023", 11);

Add("The Way Things Go", "JAE WON JUNG", "Sep 22nd, 2024 - Sep 24th, 2024", 8);
export default exhibitions;
